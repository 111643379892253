/**
 * Photo Text News CTA Layout Styles
 * DBS>Interactive
 */

 .layout-photo-text-news-cta {
    .photo{
        height: 0;
        padding-top: 65%;
	    position: relative;

        img {
            @include object-fit(cover, center);
        }
    }
   
    .news {
        background-color: $grayLighter;
        padding: $spacing;

        &__title { 
            color: $purple; 
            margin-bottom: 0.5rem;
        }

        &__btn {
            display: inline-block;
            margin-top: 1.5rem;
        }
    }

    @include media ($screen-sm) {

        .cta {
            display: flex;
            overflow: hidden;
            margin: 0 auto;

            > div {
                flex: 50%;
            }

            .news {
                display: flex;
                    align-items: center;
                    justify-content: left;
            }

            .photo {padding-top: 35%;}
        }
    }

    @include media($screen-md) {
        .cta {

            .news {
                padding: $spacing #{$spacing*2};
            }
        }
    }
}


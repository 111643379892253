.counter-icons {
	&__description {
        font-size: 1.125rem;
        line-height: 1.4;
    }

	&__top {
		margin: 0 auto 4rem;
		max-width: 55rem;
        
        h2 {
            font-weight: 700;
        }
	}

	&__items {
		justify-content: center;
	}

	&__item {
		border-bottom: 1px solid $grayLight;
        color: $grayDarker;
		margin-bottom: $spacing;
		padding-bottom: $spacing;
		text-align: center;

		&:last-child {border-bottom: none;}

		svg {
			width: 5rem;
		}

		.number,
		.symbol,
        .prefix,
		.postfix {
			font-size: 2rem;
			font-weight: bold;
		}
        
        .postfix {
            margin-left: -.25rem;
        }
	}

	&__icon {
		margin: 0 auto .5rem;
		width: 5rem;
        
        img {
            width: 100%;
        }
	}

	@include media($screen-sm) {
        .contain {
            max-width: 57rem;
        }
        
        &__description {
            font-size: 1rem;
        }

		&__items {
			display: flex;
				flex-wrap: wrap;
				justify-content: center;
			margin: 0 -1.5rem;
		}

		&__item {
			border-bottom: none;
			padding-bottom: 0;
			padding: 0 1.5rem;
			width: 25%;

			&:nth-child(4),
			&:nth-child(7) {border-right: none;}

			&:nth-child(5),
			&:nth-child(6),
			&:nth-child(7) {margin-top: 1.5rem;}
		}
	}
    
    @include media($screen-sm-max, 'max') {
        &__item {
            border-bottom: 0;
            padding-bottom: 0;
            width: calc(50% - #{$spacing / 2});
            
            &:nth-child(even) {
                border-left: 1px solid $grayLight;
                padding-left: #{$spacing / 2};
            }
            
            &:nth-child(odd) {
                padding-right: #{$spacing / 2};
            }
        }
        
        &__items {
            display: flex;
                flex-wrap: wrap;
        }
    }
}
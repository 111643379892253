/**
 * Footer styles
 * DBS>Interactive
 *
 * Classname Structure
 *
 * The rational was to avoid using elements in the stylesheet and to limit nesting.
 *
 * 		site-footer - the footer element
 *
 * 			site-footer__navigation - footer menu
 * 				footer-menu__item - footer menu item
 *
 * 			site-footer__credits - copyright info container
 *
 * 			site-footer__copyright - copyright statement
 *
 */

.site-footer {
	background: url( '#{$libraryPath}/images/footer-background.svg') no-repeat center;
		background-size: auto 100%;
		background-color: #96288F;
	padding: 4.5rem 0;
	overflow: hidden;
	clear: both;
	margin-top: auto; // Sticky footer
	position: relative;

	&__container {
		max-width: 85rem;
		padding: $spacing;
	}

	// Resets
	ul { list-style: none; margin: 0; padding: 0; }

	// Default link styles
	a {
		text-decoration: none;
		
		&:hover { text-decoration: underline; }
	}

	a:not(.site-footer__logo), p, span, .footer-menu__item {opacity: .8;}

	p a,
	a span {opacity: 1;}

	.site-footer__logo {
		display: block;
		padding-right: 3rem;

		svg {
			display: block;
			max-width: 12em;
			padding-bottom: 2rem;
		}
	}

	&__business {
		ul, li {
			display: block;
		}
	}

	&__navigation {

		.footer-menu__item {
			a {
				display: inline-block;
				padding: .333em .65rem;
			}
		}

		li { display: inline-block; }

		&.primary {
			
		}

		&.secondary {
			.footer-menu__item {
				a {color: $pink;}
			}
		}
	}

	.social-media {
		margin: $spacing 0 0;

		&__link {
			display: inline-block;
			margin: 0 .75rem 0 0;
		}

		a {
			background: #C27CBA;
			border-radius: 50%;
			display: block;
			height: 2.5rem;
			padding: .5rem;
			width: 2.5rem;
			transition: background .25s;
		
			&:hover,
			&:focus {
				background: darken(#C27CBA, 5%);
			}
		}

		svg path {
			fill: $white;
			transition: .25s ease-in-out;
		}
	}

	.legal-footer {
		display: block;
        margin-top: .5rem;

		ul, li {display: inline-block;}

		.legal-menu__item {
			border-left: 1px solid rgba($white, .5);
			margin-left: .5em;
			padding-left: .75em;

			a {
				font-weight: 400;
			}

			&:first-child {
				border-left: none;
				margin-left: 0;
				padding-left: 0;
			}
		}
	}

	&__credits {
		font-size: .85rem;
	}

	&__nav {
		ul {
			columns: 2;
				column-gap: 2rem;

			li {
				break-inside: avoid-column;
				min-width: 5rem;
			}
		}
	}

	@include media($screen-md) {
		&__container {
			display: flex;
				justify-content: space-between;
			padding: 3rem $spacing;
		}

		&__left {
			width: 24%;
		}

		&__mid {
			padding: 0 1.5rem;
			text-align: center;
			width: 52%;

			.site-footer__logo {
				display: inline-block;
				padding: 0;
			}
		}

		&__nav {
			width: 24%;
		}

		.social-media {
			margin: 0;
		}
	}
    
    @include media($screen-md-max, 'max') {
        background: url( '#{$libraryPath}/images/circle-logo.svg') no-repeat center;
            background-color: #96288F;
            background-position: 50% -2rem;
            background-size: 117%;
        padding: 2.5rem 0;
            
        a.site-footer__logo {
            padding-right: 0 !important;
            
            svg {
                max-width: 11.4rem;
            }
        }
        
        &__container {
            display: flex;
        align-items: center;
                flex-direction: column;
        }
        
        &__credits {
            order: 4;
            text-align: center;
        }
        
        &__left {
            font-size: 1rem;
            order: 2;
            padding: 1.5rem 0 2.5rem;
            
            .social-media {
                margin: 2.5rem 0 0;
                
                a {
                    height: 2rem;
                    width: 2rem;
                }
            }
        }
        
        		&__mid {
            order: 1
        }
        
        &__nav {
            font-size: 1rem;
            font-weight: 700;
            margin-bottom: 3rem;
            max-width: 23rem;
            order: 3;
    
            ul {
                columns: 3;
        
                li {
                    display: block;
                }
            }
        }
            
        .legal-footer {
            margin-top: .5rem;
        }
        
        .legal-menu__item {
            margin-bottom: .25rem;
        }
    }
    
    @include media($screen-xs-max, 'max') {
        &__navigation ul {
            columns: 2;
        }
    }
}

/**
 * To add a new font:
 * DBS>Interactive
 *
 * Take the fonts and upload them here:
 * https://transfonter.org/
 *
 * Download the resulting files and copy the woff and woff2
 * files into the appropriate directory.
 *
 * Make sure that the font-weight and font-style match the appropriate font files.
 *
 * We generally remove all of the unnecessary glyphs from the font family to make the files as
 * small as possible. If the font is a Google Font, you can copy all of its available glyphs
 * by clicking the "Glyphs" tab on the font's page. Paste those into the "characters" field on 
 * Transfonter and delete all of the characters that are not typically going to be used on an  
 * English language site. When you do this correctly the files downloaded from Transfonter will
 * be prefixed with "subset-". If the site has multiple languages, then don't do that.
 */

@font-face {
	font-family: 'Fira Sans';
	src: url('#{$libraryPath}/fonts/fira-sans/subset-FiraSans-Thin.woff2') format('woff2'),
		 url('#{$libraryPath}/fonts/fira-sans/subset-FiraSans-Thin.woff') format('woff');
	font-display: swap;
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Fira Sans';
	src: url('#{$libraryPath}/fonts/fira-sans/subset-FiraSans-ExtraLight.woff2') format('woff2'),
		 url('#{$libraryPath}/fonts/fira-sans/subset-FiraSans-ExtraLight.woff') format('woff');
	font-display: swap;
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Fira Sans';
	src: url('#{$libraryPath}/fonts/fira-sans/subset-FiraSans-Light.woff2') format('woff2'),
		 url('#{$libraryPath}/fonts/fira-sans/subset-FiraSans-Light.woff') format('woff');
	font-display: swap;
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Fira Sans';
	src: url('#{$libraryPath}/fonts/fira-sans/subset-FiraSans-Regular.woff2') format('woff2'),
		 url('#{$libraryPath}/fonts/fira-sans/subset-FiraSans-Regular.woff') format('woff');
	font-weight: 400;
	font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: 'Fira Sans';
	src: url('#{$libraryPath}/fonts/fira-sans/subset-FiraSans-Medium.woff2') format('woff2'),
		 url('#{$libraryPath}/fonts/fira-sans/subset-FiraSans-Medium.woff') format('woff');
	font-weight: 500;
	font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: 'Fira Sans';
	src: url('#{$libraryPath}/fonts/fira-sans/subset-FiraSans-SemiBold.woff2') format('woff2'),
		 url('#{$libraryPath}/fonts/fira-sans/subset-FiraSans-SemiBold.woff') format('woff');
	font-weight: 600;
	font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: 'Fira Sans';
	src: url('#{$libraryPath}/fonts/fira-sans/subset-FiraSans-Bold.woff2') format('woff2'),
		 url('#{$libraryPath}/fonts/fira-sans/subset-FiraSans-Bold.woff') format('woff');
	font-weight: 700;
	font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: 'Fira Sans';
	src: url('#{$libraryPath}/fonts/fira-sans/subset-FiraSans-ExtraBold.woff2') format('woff2'),
		 url('#{$libraryPath}/fonts/fira-sans/subset-FiraSans-ExtraBold.woff') format('woff');
	font-weight: 800;
	font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: 'Fira Sans';
	src: url('#{$libraryPath}/fonts/fira-sans/subset-FiraSans-Black.woff2') format('woff2'),
		 url('#{$libraryPath}/fonts/fira-sans/subset-FiraSans-Black.woff') format('woff');
	font-weight: 900;
	font-display: swap;
	font-style: normal;
}
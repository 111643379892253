/**
 * DBS Slate Front Page Stylesheet
 * DBS>Interactive
 *
 * This stylesheet will only be loaded on the front page.
 */

// Mixins
@import "mixins/mixins";
@import "mixins/layout";

// Required Variables and definitions
@import "base/vars";
@import "base/font";
@import "vendors/flickity/flickity";

// Layout Styles
@import "layout/layout-half-and-half";
@import "layout/layout-testimonials";
@import "layout/layout-video-text";
@import "layout/layout-three-column-services";
@import "layout/layout-photo-text-news-cta";
@import "layout/layout-contact-us-cta";
@import "layout/layout-counter-icons";
@import "layout/layout-swoop-cta";
@import "layout/layout-two-column-shortcuts";
@import "layout/layout-dynamic-image-tiles";

// Partials
@import "layout/partials/layout-statistics";
@import "layout/partials/layout-blog-feed";

// Components
@import "components/footer";

// Pages
@import "page/frontpage";


.post {
	background: $white;
	border: 1px solid #e5e5e5;
	position: relative;

	.post__image {
		background: $white;
		border: none;
		height: 0;
		overflow: visible;
		padding-bottom: 60%;
		position: relative;

		img {@include object-fit(cover, center);}

		&.placeholder {
            background-image: url('#{$libraryPath}/images/site-logo-50.svg');
            background-repeat: no-repeat;
			background-color: white;
            background-position: 50% 20%;
            background-size: 50%;
		}

		&::before {
			background: url( '#{$libraryPath}/images/hah-post-swoosh.svg') no-repeat;
				background-position: center -60%;
				background-size: 100% auto;
			content: '';
			height: 100%;
			position: absolute;
				bottom: -2.5rem;
				left: 0;
			width: 100%;
			z-index: 1;
		}
	}

	.arrow-link.small {
		color: $purple;

		&::after {
			background-color: transparent;
			background-image: url( '#{$libraryPath}/icons/src/right-caret-purple.svg');
			background-size: .5rem;
			border: none;
		}
	}

	.post__text {
		flex: 1;
		padding: $spacing;
		position: relative;
		z-index: 2;

		.arrow-link {
			align-self: flex-start;
			margin-top: auto;
		}
		
		.post__title.h5 {
			color: $grayDarkest;
			font-size: 1.125rem;
			
			@include media($screen-sm) {
				font-size: 1.25rem;
                
                a {
                    font-size: 1rem;
                }
			}
		}
		
		&-categories {
			list-style: none;
			margin-bottom: .75rem;
			padding: 0;

			li {
				margin-bottom: 1rem;
				margin-right: .5rem;

				a {
					background: $purple;
					border: 1px solid $purple;
					border-radius: 1.5rem;
					color: white;
					font-size: .8rem;
					padding: .25rem .5rem;
					text-decoration: none;

					&:active,
					&:hover,
					&:focus,
					&.active {
						background: $white;
						color: $purple;
						transition: .25s;
					}
				}
			}
		}
	}
    
    @include media($screen-sm) {
        margin: 0 .75rem 1.5rem;
		width: calc(50% - 1.5rem);
        
        .post__image {
            &.placeholder {
                background-position: 50% 40%;
            }
        }
        
        .post__text {
            a.arrow-link {
                font-size: .875rem;
            }
        }
    }
    
    @include media($screen-md) {
        width: calc(33.33% - 2.5rem);
        
        .post__image {
            &.placeholder {
                background-position: 50% 30%;
            }
        }
    }
}

@include media($screen-sm) {
	.news__wrap {
		gap: $spacing;
	}
}
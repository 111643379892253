.testimonials {
	.flickity-button {
		background: $white;
		border: 1px solid $purple;
        
        &-icon {
            fill: $purple;
        }
	}

	&__image {
		@include size(9rem);
		border-radius: 50%;
		margin: 0 auto 1.5rem;
		overflow: hidden;
		position: relative;
        
        img {
            @include object-fit(cover, center);
        }
	}

    &__slide {
        width: 100%;
    }

	&__text {
        line-height: 1.4;
		margin: 0 3rem;
	}

	&__wrapper {
        max-width: 55rem;
    }

	&.testimonials-has-background {
		background-color: $grayLighter;
		position: relative;

		.contain {
			position: relative;
			z-index: 2;
		}

		.testimonials__background {
			height: 100%;
			position: absolute;
				top: 0;
				left: 0;
			width: 100%;

            img {
                @include object-fit(cover, top);
            }
                
			&::after {
				background: linear-gradient(to right, transparent 40%, $grayLighter);
				content:'';
				height: 100%;
				position: absolute;
					top: 0;
					left: 0;
				width: 100%;
			}
		}

		.testimonials__wrapper {
			margin-left: auto;
		}

		.testimonials__slide {
			background: url('#{$libraryPath}/icons/src/quotes-left-purple-boxy.svg') 2.5rem 2.5rem/3rem auto no-repeat #fff;
			background: url('#{$libraryPath}/icons/src/quotes-left-purple-boxy.svg') 2.5rem 2.5rem/3rem auto no-repeat #fff;
			margin-right: 2rem;
			padding: 7rem 0 2.5rem;
			width: 100%;
		}

		.testimonials__heading {
			color: $black;
			margin-bottom: 2rem;
		}

		.testimonials__text {
            color: $grayDarker;
			display: flex;
				flex-direction: column;
			height: 100%;
			margin: 0;
		}

		.testimonials__author {
			margin-top: auto !important;
			padding-right: 4rem;
		}

		.flickity-viewport {
			clip-path: inset( -100vw -100vw -100vw 0 );
			overflow: visible;
		}

		.testimonials__image {
			@include size(4rem);
			margin: 0 !important;
			position: absolute;
				bottom: 2rem;
				right: 0;
		}

		@include media($screen-sm) {
            .contain {
                padding-left: 25%;
            }

            .flickity-page-dots {
                bottom: -3rem;
                width: calc(100% + 2rem);
            }
            
            .testimonials__author {
                font-size: .9375rem;
            }
            
			.testimonials__background {
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                
				img {
                    height: calc(100% - 2px);
					left: -12%;
					top: 1px;
					transform: none;
					width: 58%;
				}

				&::after {
                    width: 47%
                }
			}
            
            .testimonials__image {
                right: 2rem;
            }
            
			.testimonials__slide {
				padding: 7rem 2.5rem 2.5rem;
                width: 50%;
			}

			.contain {padding-left: 25%;}
		}
        
        @include media($screen-sm-max, 'max') {
            .testimonials__text {
                margin: 0;
    
                p {font-size: 1.125rem !important;}
            }
        }
	}
}
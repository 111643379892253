/**
 * Contact Us CTA Layout Styles
 * DBS>Interactive
 */
 .layout-contact-us-cta {

    .cta {
        background-color: $purple;
        color: white;
        padding: $spacing;

        .contact {

            &__title { 
                font-size: 1.25rem;
                letter-spacing: 1px;
            }

            .contact__content {
                color: $white !important;

                h2, h3, h4, h5, h6 {
                    color: $white !important;
                    font-weight: 300;

                    &:last-child {margin-bottom: 0;}
                }
            }
        }

        .btn-wrap {
            margin-top: $spacing;

            .button {
                background-color: $white;
                border-color: $white;
                color: $black !important;
                padding: 1rem 3rem;

                &:hover,
                &:focus {
                    background-color: $purple;
                    color: $white !important;
                }
            }
        }        
    }
    
    @include media ($screen-sm) {

        .cta {
            
            .contact {flex: 70%;}

            .btn-wrap {
                flex: 30%;
                margin-top: 0;
            }
        }

    }

    @include media($screen-md) {
        .cta {padding: $spacing*2;}
    }
}


/**
 * Dynamic Product Tiles Layout
 * DBS>Interactive
 */
 .dynamic-image-tiles {
    .cell {
        margin-bottom: $spacing;
        padding: 0 !important;
        
        img {
            display: block;
        }
    }
    
    .four-up {
        .cell {
            img {
                height: 100%;
            }
        }
    }
    
    &__heading {
        margin-bottom: $spacing;
    }
    
    &__images {
        gap: $spacing;
        margin: 0 auto;
        max-width: 46rem;
    }
    
    &.bg-light {
        background-color: $purpleLightest;
    }
    
    @include media ($screen-md) {
        .cell {
            margin-bottom: 0;
        }
        
        .three-up .cell {
            width: calc(33.33% - #{$spacing * 2 / 3});
        }
    
        .four-up .cell {
            height: 8.25rem;
            width: calc(25% - #{$spacing * 3 / 4});
        }
        
        .five-up .cell {
            width: calc(20% - #{$spacing * 4 / 5});
        }
        
        &__heading {
            margin-bottom: #{$spacing * 2};
        }
    }
    
    @include media($screen-sm-max, 'max') {
        &__images {
            display: flex;
                flex-wrap: wrap;
            
            .cell {
                margin-bottom: 0;
                width: calc(33.33% - 1rem);
            }
        }
    }
}
/**
 * Two Columns Flex Layout
 * DBS>Interactive
 */
.layout-two-column-shortcuts {

	padding: 0 1rem 0 1rem;

	.shortcuts {
		
		> :first-child {
			.shortcut__detail-title { color: $green; }
		}

		> :last-child {
			.shortcut__detail-title { color: $teal; }
			padding-bottom: 0;
			border-bottom: none;
		}
	}

	.shortcut {
		text-align: center;
		overflow: visible;
		padding: 2rem 0 2rem 0;
		border-bottom: 1px solid #ccc;

		&__detail {

			&-title { 
				margin-bottom: 0.8rem;
			}
	
			&-content {
				text-align: center;
				margin-bottom: 0.8rem;
			}
		}
	}

	@include media ($screen-sm) {

		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;

		.shortcuts {
			display: flex;

			.shortcut {
				display: flex;
				margin-bottom: 0;
				padding: 0 2rem;
				border-left: 1px solid $purple;
				border-bottom: none;
				
				&__icon {
					display: flex;
					text-align: right;
					align-items: center;
					min-width: 5rem;
					width: 10rem;
				}
		
				&__detail {
					padding-left: 1.5rem;
					text-align: left;
					&-content{ text-align: left; }
				}

				&:first-child {
					border-left: none;
				}
			}
		}

	}
}

/**
 * Partial: Blog Feed
 * DBS>Interactive
 */
.partial-blog-feed {
    @include default-margin;
    
    .blog-main {
		@include grid();
			justify-content: space-between;
	}
    
    .contain {
        max-width: 68rem;
    }
	
	.latest-news {        
        &__button {
            margin-top: 1rem;
        }
        
        &__heading {
            margin-bottom: #{$spacing * 2};
        }
    }
    
    .post {
		display: inline-block;
        
        &__text {
            a {
                &.arrow-link {
                    font-size: 1.125rem;
                    font-weight: 600;
                }
            }
        }
        
        &__title {
            a {
                color: $grayDarker;
                font-size: 1.375rem;
                font-weight: 600;
                line-height: 1.3;
            }
        }
        
		&:hover {
			.button--inline:after {
				transform: translateX(.5em);
				transition: transform .35s;
			}
		}
        
		@include media($screen-sm) {
            margin-bottom: 0;
			width: calc(100% / 3);
		}
	}
    
    @include media($screen-sm-max, 'max') {
        margin: #{$spacing * 3} 0;
        
        .post {
            &:not(:last-child) {
                margin-bottom: #{$spacing * 2};
            }
        }
    }
}

.swoop-cta {
	padding: 2.5rem 0 4rem;
	position: relative;
    
    .contain {
        padding: 0 1.5rem;
    }

	&__background {
		@include size(100%);
		background: url('#{$libraryPath}/images/swoop-background.svg') no-repeat center;
		background-size: 100% auto;
		background-color: transparent;
		position: absolute;
			top: 0;
			left: 0;
		z-index: 2;
	}

	&__wrapper {
		background: $purple !important;
        font-size: 1.125rem;
		margin: 0 auto;
		max-width: 55rem;
		padding: 4rem 1.5rem 5.5rem;
		position: relative;
		z-index: 3;

		&::before {
			@include size(4rem);
			background: url('#{$libraryPath}/images/swoop-hand.svg') no-repeat center;
				background-size: 4rem;
			content: '';
			position: absolute;
				top: -2rem;
				left: 50%;
			transform: translateX(-50%);
		}

		&::after {
			background: url('#{$libraryPath}/images/swoop-plant.svg') no-repeat;
				background-position: left bottom;
				background-size: contain;
			content: '';
			height: 6rem;
			position: absolute;
				bottom: -1rem;
				left: 1rem;
			width: 32%;
		}
	}
    
	&:after {
		background-color: #f5f5f5;
		content: '';
		height: 50%;
		position: absolute;
			top: 50%;
		width: 100%;
		z-index: 1;
	}
    
    @include media($screen-xs) {
        .contain {
            padding: 0 4rem;
        }

        &__wrapper {
            font-size: 1.375rem;
            padding: 4rem 2.6rem 5.5rem;
            
            h2 {
                font-size: 2rem;
            }
        }
    }
    
    @include media($screen-sm) {
        &__background {
            background-position: top;
        }

        &__wrapper {
            color: $grayLight;
            font-size: 1rem;
            line-height: 1.6;
            padding: 4rem 3rem 3rem;
            
            h2 {
                font-weight: 600;
            }

            &::after {
                height: 9rem;
                left: 2rem;
                width: 23%;
            }
        }
    }
    
	@include media($screen-md) {
		&__wrapper {
			padding: 4.25rem 6.4rem;
		}
	}
}
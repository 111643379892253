/**
* Video Text Layout Styles
* DBS>Interactive
*/

.layout-video-text,
.layout-image-text {
    padding: 3rem 0; // Make room for blobby background

    .video-photo {
        margin-bottom: 1rem;
        position: relative;
        
        &:before {
            background-image: url('/wp-content/uploads/home-video-plant-min.png');
                background-position: left;
                background-repeat: no-repeat;
                background-size: contain;
            content: '';
            height: 80%;
            position: absolute;
                left: 90%;
                bottom: -4%;
            width: 100%;
            z-index: 1;
        }

        &:after {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 514 463'%3E%3Cpath fill='%23FFF6E9' fill-rule='evenodd' d='M267.47 41.13c-34.6 0-90.8-41.13-120.3-41.13S23.71 24.8 8.14 73.5c-15.57 48.69 15.57 59 15.57 93.13 0 34.13-29.7 61.93-22.63 96.38 7.06 34.46 64.57 34.46 90.57 60.89 26 26.42 23.83 79.57 63.88 118.27 40.06 38.7 76.2 14.28 148.02-17.46 71.82-31.74 89.01 3.15 150.1-29.7 61.1-32.84 38.5-100.84 38.5-164.6 0-63.78 28.6-72.07 19.82-104.57-8.77-32.5-58.38-89.18-84.42-102.45-26.05-13.28-125.5 17.74-160.1 17.74z'/%3E%3C/svg%3E");
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
            content: '';
            height: 150%;
            position: absolute;
                top: 50%;
                left: -15%;
            transform: translateY(-50%);
            width: 100%;
            z-index: -1;
        }

        .embed-container {
            // iframe { display: none; }
        }

        img {@include object-fit(cover, center);}

        .icon {
            appearance: none;
            background: transparent;
            border: none;
            height: 100%;
            width: 100%;
            position : absolute;
                top: 50%;
                left : 50%;
            transform: translate(-50%, -50%);
            z-index: 2;

            &:after {
                content: '';
                display: block;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 514 463'%3E%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 83 83'%3E%3Cg stroke-miterlimit='10' transform='translate(0 -952.4)'%3E%3Ccircle cx='41.5' cy='993.9' r='41' fill='%23862d7c' stroke='%23862d7c'/%3E%3Cpath fill='%23fff' stroke='%23fff' d='M33.1 977.3c-1.9 0-3.6 1.7-3.6 4v25.1c.1 3.1 3 5 5.6 3.5l20.7-12.6a4 4 0 000-6.8L35 977.8c-.6-.3-1.3-.5-1.9-.5h0z'/%3E%3C/g%3E%3C/svg%3E%3C/svg%3E");
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 100%;
                margin: 0 auto;
                transition: transform .25s ease-in-out;
                width: 5rem;
                height: 5rem;
            }
        }
        
        img {
            width: 100%;
            height: auto;
        }
    }

    .text {padding: 0.75rem;}

    @include media ($screen-sm) {

        .contain.contain-narrow {padding-left: 3rem;}

        :first-child {flex: 55%;}

        :last-child {flex: 45%;}

        .text {
            display: flex;
                align-items: center;
                justify-content: center;
            padding: 0 0 0 3rem;
        }

        .video-photo {
            margin-bottom: 0;
        }

        // CMS Class to swap sides
        &.reverse-video {
            .flex-tab {flex-direction: row-reverse;}

            .video-photo::before {
                background-position: right;
                left: auto;
                right: -12%;
            }

            .text {
                padding-left: 0;
                padding-right: 3rem;
            }
        }
    }
    
    @include media($screen-sm-max, 'max') {
        .contain {
            padding: 0 3rem;
        }
    }
}


/**
 * Three Columns Flex Layout
 * DBS>Interactive
 */
.layout-three-column-services {

	.services-title {
		width: 100%;
		padding: 1rem;
		text-align: center;
	}

	.services {

		> div {margin-bottom: 2rem;}

		:first-child .service__title { color: $purple; }
		:nth-child(2) .service__title { color: $teal; }
		> :last-child {
			margin-bottom: 0;
			border-bottom: none;

			.service__title { color: $green; }
		}
	}

	.service {
		text-align: center;
		padding-bottom: 2rem;
		border-bottom: 1px solid rgb(165, 154, 154);

		&:last-child {padding-bottom: 0;}

		&__image {
			margin-bottom: 1rem;

			img {
				width: 100%;
				max-width: 14rem;
			}
		}

		&__title {margin-bottom: 0.8rem;}

		&__button {margin-top: 1rem;}
	}

	@include media ($screen-sm) {

		.services {
			display: flex;

			> div { 
				flex: column;
				overflow: visible;
				margin-bottom: 0;
				border-bottom: none;
				padding: 0 2rem;
				border-left: 1px solid $purple;
			}

			:first-child {
				border-left: 0;
				margin-left: -2rem;
			}

			:last-child {margin-right: -2rem;}

			.service {padding-bottom: 0;}
		}
	}
}

.two-column-services {

	@include media ($screen-sm) {
		width: 100%;

		.services {
			margin: 0 auto;
			max-width: $containSizeNarrow;

			.service {
				width: 50%;

				&__content {text-align: center;}
			}

			:last-child .service__title {color: $teal;}
		}
	}
}

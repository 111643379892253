.statistics {
	margin: 0 auto;
	max-width: $containSizeNarrow;

	&__dots {
		position: relative;
	}

	&__dot {
		border-radius: 50%;
		color: $white;
		display: flex;
			flex-direction: column;
			justify-content: center;
		font-size: 1.125rem;
		line-height: 1.2em;
		margin-bottom: 1rem;
		padding: $spacing;
		position: relative;
		transform: scale3d(0,0,0);
		transform-origin: center;
		transition-duration: .5s;
		transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.075);
		z-index: 5;

		div {
			font-size: 2.25rem;
			font-weight: 700;
			line-height: 1.2;
		}

		&.dot-1 {
			background: $green;
		}

		&.dot-2 {
			background: $purple;
			transition-delay: .75s;
		}

		&.dot-3 {
			background: $grayDark;
			transition-delay: .25s;
		}

		&.dot-4 {
			background: $teal;
			transition-delay: 1s;
		}

		&.dot-5 {
			background: $pink;
			transition-delay: 1.25s;
		}
	}

	.dot-1.in-view {
		&,
		~ .statistics__dot {transform: scale3d(1,1,1) !important;}
		~ .statistics__blob {opacity: 1;}
	}

	&__blob {
		opacity: 0;
		position: absolute;
		transition: 2s ease-in-out;

		&.blue-blob {
			height: 100%;
				top: -1.5rem;
				left: 50%;
			transform: translateX(-50%);
			width: 55%;
			z-index: 1;
		}

		&.green-blob {
			left: 0;
			transition-delay: 1s;
			width: 40%;
			z-index: 2;
		}

		&.pink-blob {
			right: 0;
			transition-delay: 1.5s;
			width: 40%;
			z-index: 2;
		}
	}

	@include media($screen-sm-max, 'max') {
		&__dots {
			display: flex;
				align-items: flex-start;
				flex-direction: column;
		}

		.dot-1 {
			@include size(14rem);
		}

		.dot-2 {
			@include size(14rem);
			align-self: flex-end;
			margin-top: -4rem;
		}

		.dot-3 {
			@include size(16rem);
			font-size: 1.25rem;
			left: -.75rem;
			margin-top: -4rem;
		}

		.dot-4 {
			@include size(14rem);
			align-self: flex-end;
			margin-top: -5rem;
			right: -.5rem;
		}

		.dot-5 {
			@include size(16rem);
			font-size: 1.25rem;
			margin-top: -4rem;
		}

		&__blob {
			width: 115% !important;

			&.green-blob {
				top: 30%;
			}

			&.pink-blob {
				top: 60%;
			}
		}
	}

	@include media($screen-sm) {

		&__dots {
			display: flex;
				align-items: center;
				flex-wrap: wrap;
				justify-content: center;
		}

		&__dot {
			margin: 0 .75rem;

			&.dot-1 {
				@include size(14rem);
				margin-top: 3rem;
			}
	
			&.dot-2 {
				@include size(18rem);
				font-size: 1.5rem;
			}
	
			&.dot-3 {
				@include size(16rem);
				
			}
	
			&.dot-4 {
				@include size(14rem);
				font-size: 1.25rem;
			}
	
			&.dot-5 {
				@include size(16rem);
				font-size: 1.125rem;
			}
		}
	}

	@include media($screen-md) {
		&__dot {
			font-size: 1.25rem;

			&.dot-1 {
				@include size(15rem);
			}
	
			&.dot-2 {
				@include size(19rem);
				font-size: 1.75rem;
			}
	
			&.dot-3 {
				@include size(16rem);
				margin-top: 3rem;
			}
	
			&.dot-4 {
				@include size(17rem);
				font-size: 1.25rem;
			}
	
			&.dot-5 {
				@include size(16rem);
				font-size: 1.125rem;
				margin-top: -2rem;
			}
		}
	}
}